import React, { Component } from 'react';


const Homesection2 = () => {

    return (
	<>
  <section className='Homebnnr'>
            <div className='container'>
                <div className='row'>
           
                </div>
            </div>
        </section>
    </>
	);
};

export default Homesection2