import React from "react";
import offer1 from "../../../src/img/offer1.png";
import offer2 from "../../../src/img/offer2.png";
import offer3 from "../../../src/img/offer3.png";
import instagramlogo from "../../../src/img/instagramlogo.png";

const Offerpagesection = () => {
  return (
    <div>
      <section className="Offerpagesection1">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 just">
              <div className="modernized ">
                <h2 className="new-heading">Early bird offer </h2>
                <p>
                Book your stay in advance and grab an exclusive discount on your bookings.
                </p>
                <div className="bnr-btn">
                  <a href="/Contact">Book Now</a>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 just">
              <img src={offer1} className="offer1" alt="" />
            </div>
          </div>
          <div className="row" id="two">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 just">
              <img src={offer2} className="offer1" alt="" />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 just">
              <div className="modernized2 ">
                <h2 className="new-heading">Extended stay savings </h2>
                <p>
                Planning a longer stay? Extend your stay with us and unlock extra savings. 
                </p>
                <div className="bnr-btn">
                  <a href="/Contact">Book Now</a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 just">
              <div className="modernized ">
                <h2 className="new-heading">Seasonal discounts </h2>
                <p>
                Experience the beauty of every season with our irresistible seasonal discounts.  
                </p>
                <div className="bnr-btn">
                  <a href="/Contact">Book Now</a>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 just">
              <img src={offer3} className="offer1" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Offerpagesection;
