import React from "react";
import Slider from "react-slick";


function Mobileslider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1
  };
  return (
    <section className="mobileside">
    <div className="slider-container">
      <Slider {...settings}>
        <div className="slidr1" >
        <div className="overlay" />
        <div className="con">
          <h2>SO MANY MORNINGS</h2>
          <p className="fi">
            Your getaway to extraordinary staycations{" "}
          </p>
          <div className="Prng">
            <a href="/villacollection">EXPLORE NOW</a>
          </div>
        </div>
        </div>
        <div  className="slidr2">
        <div className="overlay" />
        <div className="con">
          <h2>SO MANY MORNINGS</h2>
          <p className="fi">
            Your getaway to extraordinary staycations{" "}
          </p>
          <div className="Prng">
            <a href="/villacollection">EXPLORE NOW</a>
          </div>
        </div>
        </div>
        <div className="slidr3">
        <div className="overlay" />
        <div className="con">
          <h2>SO MANY MORNINGS</h2>
          <p className="fi">
            Your getaway to extraordinary staycations{" "}
          </p>
          <div className="Prng">
            <a href="/villacollection">EXPLORE NOW</a>
          </div>
        </div>
        </div>
        {/* <div className="slidr4">
        <div className="overlay" />
        <div className="con">
          <h2>SO MANY MORNINGS</h2>
          <p className="fi">
            Your getaway to extraordinary staycations{" "}
          </p>
          <div className="Prng">
            <a href="/">EXPLORE NOW</a>
          </div>
        </div>
        </div>
        <div className="slidr5">
        <div className="overlay" />
        <div className="con">
          <h2>SO MANY MORNINGS</h2>
          <p className="fi">
            Your getaway to extraordinary staycations{" "}
          </p>
          <div className="Prng">
            <a href="/">EXPLORE NOW</a>
          </div>
        </div>
        </div> */}
       
      </Slider>
    </div>
    </section>
  );
}

export default Mobileslider;
