import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import blacklogo from "../../../src/img/blacklogo.png";
import Rlbutton from '../rlbutton/rlbutton';

function Newheader() {
  const navigate = useNavigate();

  const handleVillaCollectionsClick = (event) => {
    // Prevent the dropdown menu from being triggered
    event.stopPropagation();
    navigate('/villacollection');
  };

  return (
    <section className='newheader'>
      <Navbar expand="lg" fixed="top" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">

            <img
              src={blacklogo}
              height="30"
              className="d-inline-block align-top newlogo22"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                    <NavDropdown
                title={
                  <span onClick={handleVillaCollectionsClick} style={{ cursor: 'pointer' }}>
                    Villa Collections
                  </span>
                }
                id="basic-nav-dropdown"
                onClick={(e) => e.stopPropagation()} // Stop propagation to prevent dropdown toggle
              >
                <NavDropdown.Item href="/hotels/the-log-house">
                  The Log House
                  <br className='menu-break' />
                  <span className='dfggf'>(2 BHK | 2 Beds | Maximum 6 guests)</span>
                </NavDropdown.Item>
                <NavDropdown.Item href="/hotels/the-mini-horizon">
                  The Mini Horizon
                  <br className='menu-break' />
                  <span className='dfggf'>(1 BHK | 1 Beds | Maximum 2 guests)</span>
                </NavDropdown.Item>
                <NavDropdown.Item href="/hotels/the-horizon-villa">
                  The Horizon Villa
                  <br className='menu-break' />
                  <span className='dfggf'>(4 BHK | 4 Beds | Maximum 12 guests)</span>
                </NavDropdown.Item>
                <NavDropdown.Item href="/hotels/the-silverton-chalet">
                  The Silverton Chalet
                  <br className='menu-break' />
                  <span className='dfggf'>(3 BHK | 3 Beds | Maximum 8 guests)</span>
                </NavDropdown.Item>
                <NavDropdown.Item href="/hotels/the-pink-magnolia-cottage">
                  The Pink Magnolia Cottage
                  <br className='menu-break' />
                  <span className='dfggf'>(4 BHK | 4 Beds | Maximum 8 guests)</span>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/About">About Us</Nav.Link>
              <Nav.Link href="/">Book My Stay</Nav.Link>
              <Nav.Link href="/Events-and-experiences">Events And Experiences</Nav.Link>
              <Nav.Link href="/Offers">Offers</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
            <Rlbutton />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
}

export default Newheader;
