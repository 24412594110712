import React from 'react';
import sicottageMP4 from "../../../img/sicottage.mp4";
import sicottageWebM from "../../../img/sicottage.webm";

const Videobnr = () => {
  return (
    <section className='video-bnr'>
      <div className="banner-container">
        <video autoPlay muted loop playsInline className="background-video">
          <source src={sicottageMP4} type="video/mp4" />
          <source src={sicottageWebM} type="video/webm" />
          {/* Add additional video sources if needed for different browsers */}
          Your browser does not support the video tag.
        </video>
        <div className="overlay"></div>
        <div className="content">
          <h1 className="headerTitle">
            So Many Mornings
          </h1>
          <p className="headerDesc">
            <p className="figmabnrtext">
              Your getaway to extraordinary staycations{" "}
            </p>
          </p>
          <div className="Proding">
            <a href="/villa-collections">EXPLORE NOW</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Videobnr;
