import React from "react";
import Slider from "react-slick";
import PropertySilvertonSliders from "../Slider/PropertySilvertonSliders";
import PropertySiSliders from "../Slider/PropertySiSliders";
import PropertyPinkSlider from "../Slider/PropertyPinkSlider";
import PropertyMiniSliders from "../Slider/PropertyMiniSliders";
import PropertyLoghouseSlider from "../Slider/PropertyLoghouseSlider";
import PropertyHorizonSliders from "../Slider/PropertyHorizonSliders";


const Propertiesslider = () => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows:false,
    responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
          }
        },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <div id ="property-slider">
    <Slider {...settings}>
      <div className="property-slider-list">
        <PropertySilvertonSliders />
      </div>
      <div className="property-slider-list">
        <PropertySiSliders/>
      </div>
      <div className="property-slider-list">
        <PropertyPinkSlider/>
      </div>
      <div className="property-slider-list">
        <PropertyMiniSliders/>
      </div>
      <div className="property-slider-list">
        <PropertyLoghouseSlider/>
      </div>
      <div className="property-slider-list">
        <PropertyHorizonSliders/>
      </div>
    </Slider>
    </div>
  );
}
export default Propertiesslider;