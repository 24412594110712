import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import loghouse1 from "../../assets/img/bg/horizonvilla/px-conversions/horizon3.jpg";
import loghouse2 from "../../assets/img/bg/horizonvilla/px-conversions/horizon4.jpg";
import loghouse3 from "../../assets/img/bg/horizonvilla/px-conversions/horizon5.jpg";
import loghouse4 from "../../assets/img/bg/horizonvilla/px-conversions/horizon6.jpg";
import loghouse5 from "../../assets/img/bg/horizonvilla/px-conversions/horizon7.jpg";


const PropertyHorizonSliders = () => {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    // Fetch hotel data from the API
    const fetchHotels = async () => {
      try {
        const response = await fetch(
          "https://www.api.somanymornings.com/api/hotels"
        );
        const data = await response.json();
        setProperties(data);
      } catch (error) {
        console.error("Error fetching hotel data:", error);
      }
    };

    fetchHotels();
  }, []); // Empty dependency array ensures the effect runs once after the initial render

  var settings = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
  return (
    <>
      <Slider {...settings}>
      <div className="slide-property">
          <img
            src={loghouse1}
            alt=""
            className="prslider-img"
            id="sliderpr-img"
          />
        </div>
        <div className="slide-property">
          <img
            src={loghouse2}
            alt=""
            className="prslider-img"
            id="sliderpr-img"
          />
        </div>
        <div className="slide-property">
          <img
            src={loghouse4}
            alt=""
            className="prslider-img"
            id="sliderpr-img"
          />
        </div>
        <div className="slide-property">
          <img
            src={loghouse3}
            alt=""
            className="prslider-img"
            id="sliderpr-img"
          />
        </div>
        <div className="slide-property">
          <img
            src={loghouse5}
            alt=""
            className="prslider-img"
            id="sliderpr-img"
          />
        </div>
      </Slider>
      <div className="property-head">
          <span className="fpproperty">12 Guests | 4 Bedrooms | Entire House</span>
      </div>
      <div className="img-body-head">
        <a href="/hotels/the-horizon-villa">
          <span className="fpName">The Horizon Villa</span>
        </a>
      </div>
      <div className="img-body">
        <span className="fpCity">Mussoorie, Uttrakhand, India</span>
      </div>
      <div className="img-body" id="prp-price">
        {properties.slice(5, 6).map((property, index) => (
          <div key={index}>
            <div className="img-body" id="prp-price">
              <span className="fpPrice">
                ₹ {property.cheapestPrice}{" "}
                <span className="pricespan">/ Per Night</span>
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PropertyHorizonSliders;


