import React from "react";
import Slider from "react-slick";
import Figmabnr1 from "../../img/Figma-Banner/Figmabnr1.jpg";
import Figmabnr2 from "../../img/Figma-Banner/Figmabnr2.jpg";
import Figmabnr3 from "../../img/Figma-Banner/Figmabnr3.jpg";
import Figmabnr4 from "../../img/Figma-Banner/Figmabnr4.jpg";
import Figmabnr5 from "../../img/Figma-Banner/Figmabnr5.jpg";
import Figmabnr6 from "../../img/Figma-Banner/Figmabnr6.jpg";
// import { baseUrl } from "./config";

function Newbanner() {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    waitForAnimate: false,
    arrows: false
  };
  return (
    
    // <div className="container">
      <Slider {...settings}>
        <div>
        <div className="overlay"></div>
        <img src={Figmabnr4} className="Figmabnr1" alt="" />
        </div>
        {/* <div>
        <div className="overlay"></div>
        <img src={Figmabnr1} className="Figmabnr1" alt="" />

        </div> */}
        <div>
        <div className="overlay"></div>
        <img src={Figmabnr2} className="Figmabnr1" alt="" />
        </div>
        <div>
        <div className="overlay"></div>
        <img src={Figmabnr3} className="Figmabnr1" alt="" />
        </div>
        <div>
        <div className="overlay"></div>
        <img src={Figmabnr5} className="Figmabnr1" alt="" />
        </div>
        <div>
        <div className="overlay"></div>
        <img src={Figmabnr6} className="Figmabnr1" alt="" />
        </div>

      </Slider>
    // </div>
  );
}

export default Newbanner;
