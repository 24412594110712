import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import si1 from "../../assets/img/bg/Sicottage/px-conversions/si1.jpg";
import si2 from "../../assets/img/bg/Sicottage/px-conversions/si2.jpg";
import si3 from "../../assets/img/bg/Sicottage/px-conversions/si3.jpg";
import si4 from "../../assets/img/bg/Sicottage/px-conversions/si4.jpg";
import si5 from "../../assets/img/bg/Sicottage/px-conversions/si5.jpg";

const PropertySiSliders = () => {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    // Fetch hotel data from the API
    const fetchHotels = async () => {
      try {
        const response = await fetch(
          "https://www.api.somanymornings.com/api/hotels"
        );
        const data = await response.json();
        setProperties(data);
      } catch (error) {
        console.error("Error fetching hotel data:", error);
      }
    };

    fetchHotels();
  }, []); // Empty dependency array ensures the effect runs once after the initial render
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Slider {...settings}>
        <div className="slide-property">
          <img
            src={si1}
            alt=""
            className="prslider-img"
            id="sliderpr-img"
          />
        </div>
        <div className="slide-property">
          <img
            src={si2}
            alt=""
            className="prslider-img"
            id="sliderpr-img"
          />
        </div>
        <div className="slide-property">
          <img
            src={si3}
            alt=""
            className="prslider-img"
            id="sliderpr-img"
          />
        </div>
        <div className="slide-property">
          <img
            src={si4}
            alt=""
            className="prslider-img"
            id="sliderpr-img"
          />
        </div>
        <div className="slide-property">
          <img
            src={si5}
            alt=""
            className="prslider-img"
            id="sliderpr-img"
          />
        </div>
      </Slider>
      <div className="property-head">
          <span className="fpproperty">8 Guests | 3 Bedrooms | Entire House</span>
      </div>
      <div className="img-body-head">
        <a href="/hotels/the-si-cottage">
          <span className="fpName">The SI Cottage</span>
        </a>
      </div>
      <div className="img-body">
        <span className="fpCity">Mussoorie, Uttrakhand, India</span>
      </div>
      <div className="img-body" id="prp-price">
        {properties.slice(4, 5).map((property, index) => (
          <div key={index}>
            <div className="img-body" id="prp-price">
              <span className="fpPrice">
                ₹ {property.cheapestPrice}{" "}
                <span className="pricespan">/ Per Night</span>
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PropertySiSliders;
