import React, { useState, useEffect } from 'react';
import axios from 'axios';

const InstagramPosts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Fetch Instagram posts when the component mounts
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,media_url,media_type,permalink&access_token=IGQWRQRDBfVGtYdUphS2V4NzgwSlpXbXVKSlY4Q2s4TmNPc0ZAXbnRnUHpDbVNjS2FDeHpLaGxlOXFEZA0VjTmIyd24yX0dBSGhray1uTy01N2UyX1VHalpaWkkxRlBxb1NxTVdDZA1dCR0FUMWVOU3VzRFNMWlhNTXcZD`
        );
        setPosts(response.data.data);
      } catch (error) {
        console.error('Error fetching Instagram posts:', error);
      }
    };

    fetchPosts();
  }, []); // Empty dependency array means this effect runs once after the initial render

  const handleImageClick = (permalink) => {
    window.open(permalink, '_blank');
  };

  return (
      <section className="posts">
        <div className="container">
          <div className="row posts">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 " id="instatemp">
              {posts.slice(0,2).map(post => (
                <div key={post.id} className="post">
                <div className="imgpost">
                  {post.media_type === 'IMAGE' && (
                    <img src={post.media_url} alt="Instagram Post" className="imagposts-first" onClick={() => handleImageClick(post.permalink)} />
                  )}
                  </div>
                  <div className="vidopost">
                  {post.media_type === 'VIDEO' && (
                    <video className="videoposts-first">
                      <source src={post.media_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-12">
              {posts.slice(2,6).map(post => (
                <div key={post.id} className="post">
                <div className="imgpost">
                  {post.media_type === 'IMAGE' && (
                    <img src={post.media_url} alt="Instagram Post" className="imagposts" onClick={() => handleImageClick(post.permalink)} />
                  )}
                  </div>
                  <div className="vidopost">
                  {post.media_type === 'VIDEO' && (
                    <video className="videoposts-first">
                      <source src={post.media_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-12">
              {posts.slice(6,10).map(post => (
                <div key={post.id} className="post">
                <div className="imgpost">
                  {post.media_type === 'IMAGE' && (
                    <img src={post.media_url} alt="Instagram Post" className="imagposts" onClick={() => handleImageClick(post.permalink)} />
                  )}
                  </div>
                  <div className="vidopost">
                  {post.media_type === 'VIDEO' && (
                    <video className="videoposts-first">
                      <source src={post.media_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
                </div>
              ))}
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-12">
              {posts.slice(10,14).map(post => (
                <div key={post.id} className="post">
                <div className="imgpost">
                  {post.media_type === 'IMAGE' && (
                    <img src={post.media_url} alt="Instagram Post" className="imagposts" onClick={() => handleImageClick(post.permalink)} />
                  )}
                  </div>
                  <div className="vidopost">
                  {post.media_type === 'VIDEO' && (
                    <video className="videoposts-first">
                      <source src={post.media_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-12">
              {posts.slice(14,18).map(post => (
                <div key={post.id} className="post">
                <div className="imgpost">
                  {post.media_type === 'IMAGE' && (
                    <img src={post.media_url} alt="Instagram Post" className="imagposts" onClick={() => handleImageClick(post.permalink)} />
                  )}
                  </div>
                  <div className="vidopost">
                  {post.media_type === 'VIDEO' && (
                    <video className="videoposts-first">
                      <source src={post.media_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
  );
};

export default InstagramPosts;
