import React, { useState } from 'react';
import blacklogo from "../../../src/img/blacklogo.png";
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rlbutton from '../rlbutton/rlbutton';
import Searchlistheader from '../searchlist/Searchlistheader';

const Navbar2 = () => {
  const [expanded, setExpanded] = useState(false);
  const [show, setShow] = useState(false);
  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = e => {
      setShow(false);
  }
  const handleNavDropdownClick = () => {
    window.location.href = '/villa-collections';
  };
  return (
    <section className="ftco-section">
      <div className="topbar2">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <p>Providing Extraordinary Getaways For Life-Enriching Memories</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-8 order-md-last">
            <div className="row">
              <div className="col-md-6 text-center pt-3 pb-3 mainlogo">
                <a className="navbar-brand" href="/">
                  <img src={blacklogo} className="somnylogo" alt="Logo" />
                </a>
              </div>
              <div className="col-md-6 d-md-flex justify-content-end mb-md-0 mb-3">
                <div className='newheaderbtn'>
                  <Rlbutton />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex" id='medium-top'>
            <Searchlistheader />
          </div>
        </div>
      </div>
      <Navbar expand="lg" bg="white" id='mobile-nav'>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto mynavbar">
            {/* <NavDropdown title="Villa Collections" id="basic-nav-dropdown"> */}
            <NavDropdown title="Villa Collections" id="basic-nav-dropdown" show={show}onMouseEnter={showDropdown} onMouseLeave={hideDropdown} onClick={handleNavDropdownClick} >
            <NavDropdown.Item href="/hotels/the-log-house">The Log House <br className='menu-break' /><span className='dfggf'>(2 BHK | 2 Beds | Maximum 6 guests)</span></NavDropdown.Item>
              <NavDropdown.Item href="/hotels/the-mini-horizon">The Mini Horizon <br className='menu-break' /><span className='dfggf'>(1 BHK | 1 Beds | Maximum 2 guests)</span></NavDropdown.Item>
              <NavDropdown.Item href="/hotels/the-horizon-villa">The Horizon Villa <br className='menu-break' /><span className='dfggf'>(4 BHK | 4 Beds | Maximum 12 guests)</span></NavDropdown.Item>
              <NavDropdown.Item href="/hotels/the-silverton-chalet">The Silverton Chalet <br className='menu-break' /><span className='dfggf'>(3 BHK | 3 Beds | Maximum 8 guests)</span></NavDropdown.Item>
              <NavDropdown.Item href="/hotels/the-pink-magnolia-cottage">The Pink Magnolia Cottage <br className='menu-break' /><span className='dfggf'>(4 BHK | 4 Beds | Maximum 8 guests)</span></NavDropdown.Item>
              <NavDropdown.Item href="/hotels/the-si-cottage">The SI Cottage <br className='menu-break' /><span className='dfggf'>(3 BHK | 3 Beds | Maximum 8 guests)</span></NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/About">About Us</Nav.Link>
            <Nav.Link href="/book-your-stay">Book Your Stay</Nav.Link>
            <Nav.Link href="/special-events">Special Events</Nav.Link>
            <Nav.Link href="/Offers">Offers</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </section>
  );
};

export default Navbar2;
