import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import useFetch from "../../../hooks/useFetch";
import { SearchContext } from "../../../context/SearchContext";
import SearchItem from "../../searchItem/SearchItem";
import Aboutcenter from "../Aboutcenter";
import Testimonials from "../Testimonials";

const Allhotelsearchlist = () => {
  const location = useLocation();
  const [destination, setDestination] = useState(location.state.destination);
  const [dates, setDates] = useState(location.state.dates);
  const [openDate, setOpenDate] = useState(false);
  const [options, setOptions] = useState(location.state.options);
  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);

  const { data, loading, error, reFetch } = useFetch(
    `https://www.api.somanymornings.com/api/hotels?title=${destination}&min=${min !== undefined ? min : 1}&max=${
      max !== undefined ? max : 99999
    }`
  );
  const { dispatch } = useContext(SearchContext);
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);
  const [openDestinationDropdown, setOpenDestinationDropdown] = useState(false);

  const destinations = [
    {
      name: "The Silverton Chalet",
      details: "3 BHK | 3 Beds | Max 8 Guests",
    },
    {
      name: "The Mini Horizon",
      details: "1 BHK | 1 Beds | Max 2 Guests",
    },
    {
      name: "The Horizon Villa",
      details: "4 BHK | 4 Beds | Max 12 Guests",
    },
    {
      name: "The Log House",
      details: "2 BHK | 2 Beds | Max 6 Guests",
    },
    {
      name: "The Pink Magnolia Cottage",
      details: "4 BHK | 4 Beds | Max 8 Guests",
    },
    {
      name: "The Si Cottage",
      details: "3 BHK | 3 Beds | Max 8 Guests",
    },
  ];

  const maxAdultsMap = {
    "The Silverton Chalet": 8,
    "The Mini Horizon": 2,
    "The Horizon Villa": 12,
    "The Eleven Oaks": 24,
    "The Log House": 6,
    "The Pink Magnolia Cottage": 8,
    "The Si Cottage": 8,
  };

  const handleDestinationClick = () => {
    setOpenDestinationDropdown(true);
  };

  const handleDestinationSelect = (selectedDestination) => {
    setDestination(`${selectedDestination.name} (${selectedDestination.details})`);
    setOpenDestinationDropdown(false);

    setOptions((prevOptions) => ({
      ...prevOptions,
      adult: 1,
    }));

    // Update details based on the selected destination
    setDetails(selectedDestination);
  };
  const handleDestinationChange = (e) => {
    const inputValue = e.target.value;
    const sentenceCaseInput = inputValue
      .toLowerCase()
      .replace(/\b\w/g, (c) => c.toUpperCase());
    const matchedDestination = destinations.find(
      (dest) => dest.toLowerCase() === sentenceCaseInput
    );
    setDestination(matchedDestination || sentenceCaseInput);
  };

  const handleClick = () => {
    reFetch();
    if (
      destination &&
      dates[0].startDate &&
      dates[0].endDate &&
      (options.adult > 0 || options.children > 0 || options.room > 0)
    ) {
      dispatch({
        type: "NEW_SEARCH",
        payload: { destination, dates, options },
      });
      navigate("/hotels", { state: { destination, dates, options } });
    } else {
      alert("Please fill in all required fields.");
    }
  };

  const [endDateSelected, setEndDateSelected] = useState(false);

  const handleDateChange = (item) => {
    const { startDate, endDate } = item.selection;
    if (!endDateSelected) {
      setDates([
        {
          startDate,
          endDate,
          key: "selection",
        },
      ]);
    } else {
      setDates([
        {
          startDate,
          endDate,
          key: "selection",
        },
      ]);
      setOpenDate(false);
    }
    setEndDateSelected(!endDateSelected);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      const dropdown = document.querySelector(".destinationDropdown");
      const input = document.querySelector(".Destination-top");
      if (
        dropdown &&
        !dropdown.contains(e.target) &&
        input &&
        !input.contains(e.target)
      ) {
        setOpenDestinationDropdown(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [openDestinationDropdown]);

  const handleOptionsChange = (e, option) => {
    const value = parseInt(e.target.value);
    setOptions((prev) => ({
      ...prev,
      [option]: value,
    }));
  };
    // Close the date picker when clicking outside of it
    const datePickerRef = useRef();
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          datePickerRef.current &&
          !datePickerRef.current.contains(event.target)
        ) {
          setOpenDate(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [datePickerRef]);
  return (
    <div>
      <section className="hotl-srch">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-5 col-md-12 col-12">
              <div className="searching-list">
                <h3>Book your room</h3>
                <div className="search-area">
                  <div className="lsItem">
                    <label>Destination</label>
                    <input
                      type="text"
                      placeholder="Where do you want to go? "
                      className="Destination-top"
                      onClick={handleDestinationClick}
                      onChange={handleDestinationChange}
                      value={destination}
                    />
                    {openDestinationDropdown && (
                      <div className="drpmenu">
                        <div className="destinationDropdown">
                          <h6 className="seares-rec">Recent searches</h6>
                          {destinations.map((dest) => (
                            <div
                              key={dest.name}
                              className="destinationOption"
                              onClick={() => handleDestinationSelect(dest)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                              </svg>
                              <span className="dest-name">{dest.name}</span><br/>
                              {/* <span className="dest-details">({dest.details})</span> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="lsItem" id="date-select">
                    <label>Check In - Check Out</label>
                    <span
                      className="dtinput"
                      onClick={() => setOpenDate(!openDate)}
                    >{`${format(dates[0].startDate, "MM/dd/yyyy")} - ${format(
                      dates[0].endDate,
                      "MM/dd/yyyy"
                    )}`}</span>
                    {openDate && (
                      <div ref={datePickerRef}>
                      <DateRange
                        editableDateInputs={true}
                        onChange={handleDateChange}
                        moveRangeOnFirstSelection={false}
                        ranges={dates}
                        className="date"
                        minDate={new Date()}
                      />
                      </div>
                    )}
                  </div>
                  <div className="lsItem">
                    <div className="Options-top">
                      <label>Options</label>
                    </div>
                    <div className="lsOptions">
                      <div className="lsOptionItem">
                        <span className="lsOptionText">Adults</span>
                        <input
                          type="number"
                          min={1}
                          max={maxAdultsMap[destination] || 99}
                          className="lsOptionInput"
                          placeholder={options.adult}
                          value={options.adult}
                          onChange={(e) => handleOptionsChange(e, "adult")}
                        />
                      </div>
                      <div className="lsOptionItem">
                        <span className="lsOptionText">Children</span>
                        <input
                          type="number"
                          min={0}
                          className="lsOptionInput"
                          placeholder={options.children}
                          value={options.children}
                          onChange={(e) => handleOptionsChange(e, "children")}
                        />
                      </div>
                      {/* <div className="lsOptionItem">
                        <span className="lsOptionText">Rooms</span>
                        <input
                          type="number"
                          min={1}
                          className="lsOptionInput"
                          placeholder={options.room}
                          value={options.room}
                          onChange={(e) => handleOptionsChange(e, "room")}
                        />
                      </div> */}
                    </div>
                  </div>
                  <button className="lastserchbtn" onClick={handleClick}>
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-7 col-md-12 col-12">
              <div className="listResult">
                {loading ? (
                  "loading"
                ) : (
                  <>
                    {data.map((item) => (
                      <SearchItem item={item} key={item._id} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Allhotelsearchlist;
