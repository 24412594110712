import React from "react";
import blacklog1 from "../../../src/img/blacklog1.png";
import horizonvilla from "../../img/horizonvilla.webp";
import minihorizon from "../../img/minihorizon.webp";
import loghouse from "../../img/loghouse.webp";
import TheSilvertonChalet from "../../img/TheSilvertonChalet.webp";
import Pinkmangolia from "../../img/Pinkmangolia.webp";
import junglibakri from "../../img/junglibakri.webp";
import sicottage from "../../img/sicottage.webp";


// import hrlogo from "../../assets/images/hrlogo.png";

const Footer2 = () => {
  return (
    <>
      <section className="new footer">
        <div className="container">
          <div className="row">
          <div className="col-xxl-3 col-xl-5 col-lg-5 col-md-12 col-12 " id="firstcol">
              <img src={blacklog1} alt="" className="blacklog22" />
              <p className="unforgettable">
              Welcome to So Many Mornings, where we offer a handpicked selection of luxurious accommodations for an unforgettable staycation.  
              </p>
              <div className="footerinfo">
                <p>
                  <span className="beyond text-white">
                    <strong>Email: </strong>
                  </span>
                  <a
                    className="text-white"
                    href="mailto:somanymornings@gmail.com"
                  >
                    somanymornings@gmail.com
                  </a>
                </p>
                <p>
                  <span className="beyond text-white">
                    <strong>Tel: </strong>
                  </span>
                  <a className="text-white" href="tel:+91-9878908399">
                    +91-9878908399
                  </a>
                </p>
              </div>
            </div>
            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-12">
              <ul className="Mangolia-list">
                <li>
                  <a href="/hotels/the-horizon-villa">The Horizon Villa</a>
                </li>
                <li>
                  <a href="/hotels/the-log-house">The Log House</a>
                </li>
                <li>
                  <a href="/hotels/the-pink-magnolia-cottage"> The Pink Magnolia Cottage</a>
                </li>
                <li>
                  <a href="/hotels/the-silverton-chalet">The Silverton Chalet</a>
                </li>
                <li>
                  <a href="/hotels/the-si-cottage">The SI Cottage</a>
                </li>
                <li>
                  <a href="/hotels/the-mini-horizon"> The Mini Horizon</a>
                </li>
              </ul>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-12">
              <ul className="Mangolia-list">
                <li>
                  <a href="/villa-collections">Villa Collections</a>
                </li>
                <li>
                  <a href="/About">About</a>
                </li>
                <li>
                  <a href="/book-your-stay">Book Your Stay</a>
                </li>
                <li>
                  <a href="/special-events">Special Events</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
                <li>
                  <a href="/Offers">Offers</a>
                </li>
              </ul>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-12">
              <ul className="Mangolia-list">
                {/* <li>
                  <a href="/">Refund Policy</a>
                </li> */}
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/Cancellation-policy">Cancellation Policy</a>
                </li>
                <li>
                  <a href="/terms-conditions">Terms & Conditions</a>
                </li>
                
              </ul>
            </div>
            <div className="col-xxl-23 col-xl-3 col-lg-2 col-md-6 col-12" id="fifth-footer">
              <div className="image-gallery">
              <h5>Gallery</h5>
                <ul>
                  <li><img src={horizonvilla} className="horizonvilla01" alt="" /></li>
                  <li><img src={minihorizon} className="horizonvilla01" alt="" /></li>
                  <li><img src={loghouse} className="horizonvilla01" alt="" /></li>
                  <li><img src={TheSilvertonChalet} className="horizonvilla01" alt="" /></li>
                </ul>
                <ul>
                <li><img src={TheSilvertonChalet} className="horizonvilla01" alt="" /></li>
                  <li><img src={Pinkmangolia} className="horizonvilla01" alt="" /></li>
                  <li><img src={junglibakri} className="horizonvilla01" alt="" /></li>
                  <li><img src={sicottage} className="horizonvilla01" alt="" /></li>
            
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="whatsapp">
          < a href="https://api.whatsapp.com/send?phone=919876769096&amp;text=Hello! I am Looking for Digital Marketing Service">
          <i class="icofont-whatsapp"></i></a>
        </div> */}
      <div className="copyright-area copyright-style-one no-border">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="copyright-right">
                <p className="copyright-text">
                  {" "}
                  © Copyright 2024. All Rights Reserved by{" "}
                  <a className="Copyright" href="/">
                    somanymornings
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer2;
