import React from "react";
import preffer1 from "../../img/gallery/preffer1.png";
import preffer2 from "../../img/gallery/preffer2.png";
import preffer3 from "../../img/gallery/preffer3.png";
import preffer4 from "../../img/gallery/preffer4.png";



function Prefferance() {

  return (
    <>
<section className="Prefferance">
    <div className="container">
     <div className="row">
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6  col-12" id="teseting">
      <div className="Prefferance-box">
    <div className="card-Prefferance">
   <img src={preffer1} className="hoping1" alt="" />
     </div>
     <div className="Prefferance-content">
      <p className="Couples">Couples</p>
      <p className="lightsd">2 Guests</p>
     </div>
</div>

       </div>
       <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6  col-6 col-12" id="teseting">
      <div className="Prefferance-box">
    <div className="card-Prefferance">
   <img src={preffer2} className="hoping1" alt="" />
     </div>
     <div className="Prefferance-content">
      <p className="Couples">Small Families</p>
      <p className="lightsd">3-4 Guests</p>
     </div>
</div>

       </div>
       <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12" id="teseting">
      <div className="Prefferance-box">
    <div className="card-Prefferance">
   <img src={preffer3} className="hoping1" alt="" />
     </div>
     <div className="Prefferance-content">
      <p className="Couples">Big Families</p>
      <p className="lightsd">5-14 Guests</p>
     </div>
</div>

       </div>
       <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6  col-12" id="teseting">
      <div className="Prefferance-box">
    <div className="card-Prefferance">
   <img src={preffer4} className="hoping1" alt="" />
     </div>
     <div className="Prefferance-content">
      <p className="Couples">Large Groups</p>
      <p className="lightsd">14+ Guests</p>
     </div>
</div>

       </div>
      
            
            
                
            </div>
        </div>

</section>
    </>

  );
}

export default Prefferance;
