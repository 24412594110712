import {
  faBed,
  faCalendarDays,
  faCar,
  faPerson,
  faPlane,
  faTaxi,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { AuthContext } from "../../context/AuthContext";
import SearchList from "../searchlist/searchlist";

// import Login from "./pages/login/Login";

const Staysbnner = ({ type }) => {
  const { user } = useContext(AuthContext);

  return (
    <section className="Staysbnner height-850 bg-overlay bg_image">
      <div className="container">
        <div className="dcounts">
          <h1 className="headerinner">Villa Collections</h1>
          <p className="figmabnrtext">
            Where every stay is a hug of comfort and luxury
          </p>
        </div>
      </div>
    </section>
  );
};

export default Staysbnner;
