import rightpick from "../../img/facilities-pics/rightpick.png";
import Footer2 from "../../components/footer/Footer2";
import Navbar2 from "../../components/navbar/Navbar2";
import Gallery2 from "../../components/newsite/gallery2";
import Holidaytrip from "../../components/newsite/holiday-trip";
import Instapost from "../../components/newsite/instapost";
import Newbanner from "../../components/newsite/newbanner";
import Prefferance from "../../components/newsite/prefferance";
import Testimonials from "../../components/newsite/testimonials";
import SearchList2 from "../../components/searchlist/searchlist2";
import amani1 from "../../img/facilities-pics/amani1.png";
import amani2 from "../../img/facilities-pics/amani2.png";
import amani3 from "../../img/facilities-pics/amani3.png";
import amani4 from "../../img/facilities-pics/amani4.png";
import amani5 from "../../img/facilities-pics/amani5.png";
import amani6 from "../../img/facilities-pics/amani6.png";
import TransparentNavbar from "../../components/navbar/Navbar";
import Propertiesslider from "../../components/featuredProperties/Propertiesslider";
import Mobileslider from "../../components/newsite/Mobileslider";
import Newheader from "../../components/navbar/Newheader";
import Videobnr from "../../components/Homestay-components/Videobnr/Videobnr";
import Tagsgallery from "../../components/newsite/Tagsgallery";

const Myhome = () => {
  return (
    <div>
      <Newheader />
      <Navbar2 />
      {/* <TransparentNavbar /> */}
      <Videobnr />
      {/* <section className="mynewbnner">
        <div className="contec">
          <h2>SO MANY MORNINGS</h2>
          <p className="figmabnrtext">
            Your getaway to extraordinary staycations{" "}
          </p>
          <div className="Proding">
            <a href="/">EXPLORE NOW</a>
          </div>
        </div>
        <Newbanner />
      </section> */}
      <Mobileslider />
      <section className="seachsection">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="list-outer-search">
                <SearchList2 />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ourameneites">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-7 col-lg-6 col-md-12 col-12">
              <div className="anw">
                <h5 className="new-sub-heading">
                  Discover more than the basics
                </h5>
                <h2 className="new-heading">Our Signature amenities </h2>
                <div className="row" id="Experience0">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="new-ameneites-box">
                      <span>
                        <img src={amani5} className="amani1" alt="" />
                      </span>
                      <div className="unparalleled">
                        <h3>Cozy fireplace evenings </h3>
                        <p>
                          Experience enchanting evenings basking in the warmth
                          and comfort of cozy fireplaces.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="new-ameneites-box">
                      <span>
                        <img src={amani6} className="amani1" alt="" />
                      </span>
                      <div className="unparalleled">
                        <h3>Private chef dining experiences </h3>
                        <p>
                          Create memorable dining experiences as our talented
                          chefs cater to your culinary desire.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="new-ameneites-box">
                      <span>
                        <img src={amani3} className="amani1" alt="" />
                      </span>
                      <div className="unparalleled">
                        <h3>Barbecue provision </h3>
                        <p>
                          Savour the flavours of outdoor cooking and enjoy
                          delicious barbecue nights amid picturesque backdrops.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="new-ameneites-box">
                      <span>
                        <img src={amani4} className="amani1" alt="" />
                      </span>
                      <div className="unparalleled">
                        <h3>Free high-speed internet </h3>
                        <p>
                          Enjoy access to high-speed internet and stay connected
                          with the digital world throughout your stay.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="new-ameneites-box">
                      <span>
                        <img src={amani1} className="amani1" alt="" />
                      </span>
                      <div className="unparalleled">
                        <h3>Daily housekeeping </h3>
                        <p>
                          Let us ensure your comfort and convenience with our
                          impeccable standards of cleanliness and hygiene.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="new-ameneites-box">
                      <span>
                        <img src={amani2} className="amani1" alt="" />
                      </span>
                      <div className="unparalleled">
                        <h3>Gourmet breakfast delights </h3>
                        <p>
                          Wake up to delight every morning as you indulge in our
                          scrumptious breakfast offerings.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-5 col-lg-6 col-md-12 col-12 just">
              <img src={rightpick} className="rightpick" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="newproperty">
        <div className="container">
          <div className="row" id="rolerow">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-7 col-12">
              <h5 className="new-sub-heading">our exquisite properties </h5>
              {/* <h2 className="new-heading">VILLAS. SUITES. COTTAGES </h2> */}
              <h2 className="new-heading">Villas. Suites. Cottages </h2>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-5 col-12">
              <p className="Accommoions">
                <a className="newlink" href="/villa-collections">
                  Browse Our Villa Collection{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="PropertySlider-list" className="PropertySlider">
        <div className="container">
          <div className="row PropertySlider">
            <Propertiesslider />
          </div>
        </div>
      </section>
      <section className="NATURE">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
              <h5 className="new-sub-heading">Step into luxury living </h5>
              <h2 className="cap-heading">
                So Many Mornings offers a curated collection of premium
                accommodations nestled amid magnificent views that evoke a sense
                of luxury and timeless aesthetics.{" "}
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="mygallery">
        <div className="container-fluid">
          <Gallery2 />
        </div>
      </section>
      {/* <section className="holidays">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
              <h5 className="new-sub-heading">Beyond your stay </h5>
              <h2 className="new-heading">Unforgettable adventures await </h2>
            </div>
          </div>
        </div>
      </section>
      <Holidaytrip /> */}
      <Tagsgallery />
      <section className="prefresnce">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
              <h5 className="new-sub-heading">Pick your ideal escape </h5>
              <h2 className="new-heading">
                We’ve got options for every <br /> traveller’s needs{" "}
              </h2>
            </div>
          </div>
        </div>
      </section>
      <Prefferance />
      <section className="client-reviews">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
              <h5 className="new-sub-heading">
                Delighted words that keep our wings soaring
              </h5>
              <h2 className="new-heading">Rave reviews </h2>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <Instapost />
      {/* <InstagramPosts/>  */}
      <section className="lastcta">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12 col-md-12 col-12">
              <div className="outboxer">
                <h5 className="new-sub-heading">Book your getaway now! </h5>
                <h3 className="new-heading-2">
                  Let us be your hosts as you create cherished memories away
                  from the hustle and bustle. Book your stay with us and open
                  doors to a world of luxury, comfort and heartfelt hospitality.{" "}
                </h3>
                <div className="signupbtn">
                  <a href="/Register">SIGN UP</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </div>
  );
};

export default Myhome;
