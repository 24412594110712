import React from "react";

import InstagramPosts from "../InstagramPosts/InstagramPosts";
function Instapost() {

  return (
   <section className="instapost">
  <div className="container">
    <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
            <h5 className="new-sub-heading">OUR INSTAGRAM <span className="instap"><a href="https://www.instagram.com/somanymornings_">@SOMANYMORNINGS_</a></span></h5>
            <h2 className="new-heading">Follow us </h2>
            </div>
        </div>
        <InstagramPosts />
        {/* <div className="row">
        <div className="col-xxl-4 col-md-5">
            <div className="ista-box">
                <img src={instapost1} className="instapost1" alt="" />
            </div>
        </div>
        <div className="col-xxl-8 col-md-7">
            <div className="row">
            <div className="col-xxl-3 col-md-6">
            <img src={instapost3} className="instapost2" alt="" />
            </div>
            <div className="col-xxl-3 col-md-6">
            <img src={instapost5} className="instapost2" alt="" />
            </div>
            <div className="col-xxl-3 col-md-6">
            <img src={instapost6} className="instapost2" alt="" />
            </div>
            <div className="col-xxl-3 col-md-6">
            <img src={instapost2} className="instapost2" alt="" />
            </div>
             <div className="col-xxl-3 col-md-6">
            <img src={instapost7} className="instapost2" alt="" />
            </div>
            <div className="col-xxl-3 col-md-6">
            <img src={instapost8} className="instapost2" alt="" />
            </div>
            <div className="col-xxl-3 col-md-6">
            <img src={instapost9} className="instapost2" alt="" />
            </div>
            <div className="col-xxl-3 col-md-6">
            <img src={instapost4} className="instapost2" alt="" />
            </div>
            </div>
        </div>
        </div> */}
    </div>
   </section>
  );
}

export default Instapost;
