import React from "react";
import Slider from "react-slick";
import gallerypick1 from "../../img/gallery/gallerypick1.png";
import gallerypick2 from "../../img/gallery/gallerypick2.png";
import gallerypick3 from "../../img/gallery/gallerypick3.png";
import gallerypick4 from "../../img/gallery/gallerypick4.png";
import gallerypick5 from "../../img/gallery/gallerypick5.png";
import gallerypick6 from "../../img/gallery/gallerypick6.png";

// import gallerypick4 from "./gallerypick4.jpg";
// import gallerypick5 from "./gallerypick5.jpg";

function Gallery2() {
  const imagePairs = [
    { initial: gallerypick4, hover: gallerypick1 },
    { initial: gallerypick3, hover: gallerypick5 },
    { initial: gallerypick2, hover: gallerypick6 },
    { initial: gallerypick4, hover: gallerypick1 },
    { initial: gallerypick3, hover: gallerypick5 },
    { initial: gallerypick2, hover: gallerypick6 },
    // Add more image pairs as needed
  ];

  const settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    cssEase: "linear",
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <button className="slick-prev" aria-label="Previous" />,
    nextArrow: <button className="slick-next" aria-label="Next" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
<section className="mygallery2">
<div className="container">
      <Slider {...settings}>
        {imagePairs.map((imagePair, index) => (
          <div key={index}>
            <div className="image-wrapper">
              <img src={imagePair.initial} alt="" className="initial-image" />
              <img src={imagePair.hover} className="hover-image" alt="" />
            </div>
          </div>
        ))}
      </Slider>
      </div>
</section>
  );
}

export default Gallery2;
