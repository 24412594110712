import React, { useState } from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import blacklogo from "../../../src/img/blacklogo.png";
import Rlbutton from "../rlbutton/rlbutton";

const TransparentNavbar = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <section className="nwheader">
      <Navbar expand="lg" variant="dark" fixed="top" formobilemenu p-md-3>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={blacklogo}
              height="30"
              className="d-inline-block align-top newlogo22"
              alt="Logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle
            onClick={() => setExpanded(!expanded)}
            aria-controls="navbar-nav"
          />

          <Navbar.Collapse
            id="navbar-nav"
            className={`justify-content-center ${expanded ? "show" : ""}`}
          >
            <Nav className="ml-auto">
              <Nav.Link href="/villa-collections">Villa Collections</Nav.Link>
              <Nav.Link href="/About">About Us</Nav.Link>
              <Nav.Link href="/book-your-stay">Book My Stay</Nav.Link>
              <Nav.Link href="/special-events">Special Events</Nav.Link>
              <Nav.Link href="/Offers">Offers</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
              {/* Button on the right side */}
              <Rlbutton />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
};

export default TransparentNavbar;
