import React from "react";
const Sliderproperties = () => {
    
  return (
    <div>


 </div>

  );
};

export default Sliderproperties;
